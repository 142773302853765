import React from "react";
import { socialIcons } from "./icons";
import useInViewport from "./useInViewport";
import { Link } from "react-router-dom";
const Footer = ({ lobyLogo }) => {
  const [isVisible2, ref2] = useInViewport({ threshold: 0.3 });
  return (
    <>
      <div className="container max-w-[1246px] text-[#9379EC]" ref={ref2}>
        <img
          src={lobyLogo ?? "/img/lobyBigLogo.svg"}
          className="w-full"
          alt=""
          className="duration-1000 transition-all"
          style={{
            transform: !isVisible2 ? "translateY(100%)" : "",
          }}
        />
      </div>
      <footer className="bg-white relative">
        <div className="pb-[70px] md:pb-[126px] pt-[70px] md:pt-[106px]">
          <div className="container">
            <div className="flex flex-wrap justify-between">
              <img src="/img/logo.svg" width={150} alt="" />
              <div className="w-full max-w-[616px] flex flex-wrap justify-between gap-x-7 gap-y-12 footer-links">
                <div className="item">
                  <a className="link" href="">
                    Reviews
                  </a>
                  <a className="link" href="">
                    Find roommate
                  </a>
                  <a className="link" href="">
                    How it works
                  </a>
                  <Link className="link" to="/delete-account">
                    Delete account
                  </Link>
                </div>
                <div className="item">
                  <a className="link" href="">
                    Our partners
                  </a>
                  <a className="link" href="">
                    Our story
                  </a>
                </div>
                <div className="max-sm:w-full">
                  <h6 className="subtitle">Follow us:</h6>
                  <div className="flex gap-2">
                    <a href="" className="flex">
                      {socialIcons.linkedin}
                    </a>
                    <a href="" className="flex">
                      {socialIcons.instagram}
                    </a>
                    <a href="" className="flex">
                      {socialIcons.discord}
                    </a>
                    <a href="" className="flex">
                      {socialIcons.facebook}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-[#2E3C43] text-sm mt-10 xl:mt-20">
              All rights reserved loby
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
