import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { cn } from "../../../components/cn";
import { icons } from "../../../components/icons";
import useInViewport from "../../../components/useInViewport";
import GetStarted from "./GetStarted";

const RoommateSection = () => {
	const [isVisible, ref] = useInViewport({ threshold: 0.3 });
	return (
		<section className="relative">
			<div
				className={cn("roommate-section bg-purple-light overflow-hidden", {
					"anime-2": !isVisible,
				})}
				style={{
					WebkitMask: `url(/img/roommate-section.png) no-repeat center center / 110% 100%`,
				}}
			>
				<div className="container">
					<h2 className="h2 max-w-[689px]">
						Getting a roommate saves 30% cost on rent
					</h2>
					<svg
						width="484"
						height="24"
						viewBox="0 0 484 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="text-purple md:text-[#FCFCFC] mb-14 max-w-[63%]"
					>
						<path
							d="M6.34487 17.922C35.7673 17.021 65.2031 14.1417 94.5767 12.3566C209.258 5.38701 362.467 4.5208 477.324 8.00037"
							stroke="currentColor"
							strokeWidth="12"
							strokeLinecap="round"
						/>
					</svg>
					<Swiper
						className="slides-per-view-auto"
						spaceBetween={24}
						slidesPerView="auto"
						breakpoints={{
							0: {
								slidesPerView: "auto",
							},
							1200: {
								spaceBetween: 36,
								slidesPerView: "3",
							},
						}}
						ref={ref}
					>
						{data.map((item, index) => (
							<SwiperSlide key={index} className="h-auto">
								<ReviewCard {...item} />
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
			<div className="text-center absolute bottom-10 sm:bottom-16 lg:bottom-[130px] left-0 w-full z-[10]">
				<GetStarted />
			</div>
		</section>
	);
};
const ReviewCard = ({ title, name, img, text }) => {
	return (
		<>
			<div className="review-card">
				<div className="flex sm:gap-2 mb-[15px]">
					{icons.star}
					{icons.star}
					{icons.star}
					{icons.star}
					{icons.star}
				</div>
				<h4 className="h4 mb-[15px] max-w-[290px] max-md:text-[18.4px] max-md:leading-6">
					{title}
				</h4>
				<div className="text-dark-blue text-opacity-80 mb-5 max-md:text-[10.51px] max-md:leading-[14px]">
					{text}
				</div>
				<div className="flex items-center gap-[14px] mt-auto max-md:text-[10.51px] max-md:leading-[14px]">
					<img
						src={img}
						className="aspect-square rounded-full w-[56px] object-cover max-md:w-9"
						alt=""
					/>
					<h6 className="text-dark-blue text-opacity-80">{name}</h6>
				</div>
			</div>
		</>
	);
};
const data = [
	{
		title: "Much better option than Spareroom",
		text: "More flexible for professionals who are here potentially less than a year.",
		name: "Savannah",
		img: "/img/reviews/1.png",
	},
	{
		title: "It was a great experience!",
		text: "There are so many categories I can choose to help me match the most compatible roommates. 💗And I rly like the interface of room, which makes me feel energetic and comfortable. Btw it would be better to let me preview all of my choice before or after submission.",
		name: "Emanuella",
		img: "/img/reviews/2.png",
	},
	{
		title: "It gave me lots of new options",
		text: "The personalized categories allowed me to find someone compatible with my lifestyle and preferences. The platform's interface is visually appealing and easy to navigate.",
		name: "Owen",
		img: "/img/reviews/3.png",
	},
];
export default RoommateSection;
