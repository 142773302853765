import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "swiper/css/bundle";
import Home from "./pages/home/Home";
import Kcl from "./pages/kcl/Kcl";
import Lse from "./pages/lse/Lse";
import ThankYou from "./pages/thank-you/ThankYou";
import DeleteAccount from "./pages/delete-account/DeleteAccount";
import { useEffect } from "react";
function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kcl" element={<Kcl />} />
        <Route path="/lse" element={<Lse />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
